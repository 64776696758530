import React, { useState } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import jsonp from 'jsonp';
import queryString from 'query-string';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import InputGroup from '../components/input-group';
import Button from '../components/button';
import styles from './waiting-list.module.scss';
import BackButton from '../components/button-back';
import { Persist } from '../components/formik-persist';
import Footer from '../components/footer';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Moet een geldig email-adres zijn.')
    .required('Email is verplicht.'),
});

const mailchimpPostUrl = `https://your-source.us3.list-manage.com/subscribe/post-json?u=7bfd2c834f300450f994e0844&amp;id=db6055f891`;

const WaitingListPage = () => {
  const [{ status, message }, setState] = useState({
    status: 'none',
    message: '',
  });

  function mailchimpSubmit({ email }) {
    const params = queryString.stringify({ EMAIL: email });
    const url = mailchimpPostUrl + '&' + params;

    console.log(url);

    jsonp(url, { param: 'c' }, (err, data) => {
      if (err) {
        setState({ status: 'error', message: err });
      } else if (data.result !== 'success') {
        setState({ status: 'error', message: data.msg });
      } else {
        setState({ status: 'success', message: data.msg });
      }
    });
  }

  function handleSubmit(data) {
    setState(state => ({ ...state, status: 'sending' }));
    mailchimpSubmit(data);
  }

  console.log({ status, message });

  return (
    <Layout>
      <SEO title="Schrijf je in voor de wachtlijst" />
      <div className={styles.MainSection}>
        <Container>
          <BackButton to="/">Terug</BackButton>

          <h1>Schrijf je in voor de wachtlijst!</h1>
          {status !== 'success' && (
            <Formik
              initialValues={{ email: '' }}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  {/* <label>Email</label> */}
                  <Field
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    component={InputGroup}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  ></ErrorMessage>
                  <div>
                    <Button disabled={status === 'sending'} type="submit">
                      Inschrijven
                    </Button>
                  </div>

                  <Persist name="waiting-list-form" />
                </form>
              )}
            </Formik>
          )}
        </Container>
      </div>

      {status !== 'none' && (
        <Container>
          <div dangerouslySetInnerHTML={{ __html: message }}></div>
        </Container>
      )}

      <Container>
        <Footer />
      </Container>
    </Layout>
  );
};

export default WaitingListPage;
