import React from 'react';
import { Link } from 'gatsby';
import mergeProps from '../util/merge-props';
import Arrow from '../images/arrow.svg';
import styles from './button.module.scss';
import backStyles from './button-back.module.scss';

/**
 * @param {import("gatsby").GatsbyLinkProps} props
 */
const BackButton = ({ children, ...props }) => (
  <Link
    {...mergeProps(
      { className: { [styles.Button]: true, [backStyles.BackButton]: true } },
      props
    )}
  >
    <span>
      <Arrow />
    </span>
    {children}
  </Link>
);

export default BackButton;
