import React from 'react';
import PropTypes from 'prop-types';
import mergeProps from '../util/merge-props';
import styles from './input-group.module.scss';

function InputGroup({ field = {}, form, ...props }) {
  return (
    <input
      {...mergeProps({ className: styles.InputGroup, ...field }, props)}
    ></input>
  );
}

InputGroup.propTypes = {
  disabled: PropTypes.bool,
};

export default InputGroup;
