import React from 'react';
import mergeProps from '../util/merge-props';
import styles from './button.module.scss';

/**
 * @param {import("gatsby").GatsbyLinkProps} props
 */
const Button = props => (
  <button {...mergeProps({ className: styles.Button }, props)} />
);

export default Button;
